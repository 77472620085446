import BlogListing from '/components/cms/BlogListing';
import Head from '/components/Head';
import MainLayout from '/layouts/MainLayout';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';
import { searchLive } from '/services/cms';

const News = ({ blogs }) => {
	return (
		<div
			className={classNames(
				'container flex flex-col max-w-7xl py-2 px-4',
				'md:relative md:w-full ',
			)}>
			<Head />

			<Typography className="mb-4" component="h1" variant="subhead-lg">
				The Blog
			</Typography>

			<BlogListing posts={blogs} />
		</div>
	);
};

export async function getServerSideProps() {
	let blogsResponse = await searchLive({
		pageType: 'blog',
		sortOrder: -1,
		sortProperty: 'publishDate',
	});

	if (!blogsResponse || blogsResponse.error === true) {
		return {
			props: {},
		};
	}

	return {
		props: {
			blogs: blogsResponse.results,
			totalCount: blogsResponse.totalCount,
		},
	};
}

News.propTypes = {
	blogs: PropTypes.array,
	totalCount: PropTypes.number,
};

News.getLayout = (page) => <MainLayout>{page}</MainLayout>;

export default News;
