import Clickable from '/components/Clickable';
import Image from '/components/Image';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';

const BlogListing = ({ baseUrl = '/blogs/news', posts }) => {
	if (!posts || posts.length === 0) {
		return null;
	}

	const first = posts[0];
	const rest = posts.slice(1);

	const firstSummary = first?.meta?.searchEngineDescription;

	return (
		<>
			<div className="flex flex-col md:flex-row gap-8 mb-8">
				{first.leadImageSrc && (
					<div className="flex justify-center">
						<Clickable linkToInternal={`${baseUrl}/${first.handle}`}>
							<Image alt={first.title} height={450} src={first.leadImageSrc} width={450} />
						</Clickable>
					</div>
				)}
				<div className="flex flex-col">
					{first.handle && (
						<Clickable linkToInternal={`${baseUrl}/${first.handle}`}>
							<Typography component="h3" variant="subtitle-lg">
								{first.title}
							</Typography>
						</Clickable>
					)}
					{first.handle && (
						<Clickable linkToInternal={`${baseUrl}/${first.handle}`}>
							<Typography className="italic text-gray-600" component="h4" variant="body">
								{first.publishDate}
							</Typography>
						</Clickable>
					)}
					{firstSummary && (
						<Typography component="p" variant="body">
							{firstSummary}
						</Typography>
					)}
					{first.handle && (
						<Clickable linkToInternal={`${baseUrl}/${first.handle}`}>
							<Typography className="text-primary" component="p" variant="heading-sm">
								Read More
							</Typography>
						</Clickable>
					)}
				</div>
			</div>
			{rest && rest.length > 0 && (
				<>
					<Typography className="mb-4" component="h2" variant="subtitle-lg">
						Past Posts
					</Typography>
					<div className="grid grid-cols-2 md:grid-cols-4 gap-4">
						{rest.map((blog, i) => {
							return (
								<div className="flex flex-col mb-8" key={i}>
									{blog.leadImageSrc && (
										<Clickable linkToInternal={`${baseUrl}/${blog.handle}`}>
											<Image alt={blog.title} height={300} src={blog.leadImageSrc} width={300} />
										</Clickable>
									)}
									{blog.handle && (
										<>
											<Clickable linkToInternal={`${baseUrl}/${blog.handle}`}>
												<Typography className="text-gray-500" component="h4" variant="body">
													{blog.publishDate}
												</Typography>
											</Clickable>
											<Clickable linkToInternal={`${baseUrl}/${blog.handle}`}>
												<Typography className="font-semibold" component="h4" variant="body">
													{blog.title}
												</Typography>
											</Clickable>
											<Clickable linkToInternal={`${baseUrl}/${blog.handle}`}>
												<Typography className="text-primary" component="p" variant="heading-sm">
													Read More
												</Typography>
											</Clickable>
										</>
									)}
								</div>
							);
						})}
					</div>
				</>
			)}
		</>
	);
};

BlogListing.propTypes = {
	baseUrl: PropTypes.string,
	posts: PropTypes.array,
};

export default BlogListing;
